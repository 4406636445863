import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import { Button, Row, InputGroup, Col, Form } from "react-bootstrap";
import moment from "moment";
import { CSVLink } from 'react-csv';
import {
  productsList,
  getAttributes,
  getTaxgsts,
  getWarrantyProccesses,
  getWarranty,
  resetProductList,
  uploadProducts,
  getCategories,
  productsListExport,
  activeGroups,
} from "../../reducers/commonReducer";
import UpdateProduct from "./UpdateProduct";
import {
  getBrandsFrontend,
  getCategoriesFrontEnd,
} from "../../reducers/frontEndReducer";
import ProductImageUpdate from "./ProductImageUpdate";
import { home } from "../../const";
import ExportPdf from "./ExportPdf";

const ProductsList = () => { 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState();
  const [categories, setCategories] = useState([]);
  const [status, setStatus] = useState(true);
  const [page, setPage] = useState(1);
  const [productsFile, setProductsFile] = useState([]);
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate(home);
    }
    dispatch(productsListExport({status}));
    dispatch(getCategoriesFrontEnd());
    dispatch(getAttributes());
    dispatch(getTaxgsts());
    dispatch(getWarrantyProccesses());
    dispatch(getBrandsFrontend());
    dispatch(getWarranty());
    // dispatch(getCustomizeEnabledProducts());
    dispatch(activeGroups())
    dispatch(getCategories())
  }, []);
  useEffect(() => {
    let timeOut = setTimeout(() => {
      dispatch(resetProductList());
      dispatch(
        productsList({
          search: search,
          categories,
          status
        })
      );
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search]);
  const { productsListData,getCategoriesData,productsListExportData, productsBlank } = useSelector(
    (state) => state.commonReducer
  );
  const header = [
    "Sr",
    "Name",
    "Reward",
    "RewardStatus",
    "Alias",
    "Slug",
    "Date",
    "Quantity",
    "Mrp",
    "QuantityAndPrice",
    "Weight",
    "Orderby",
    "Minqty",
    "Maxqty",
    "minStockQty",
    "ProductType",
    "Stock",
    "HotSelling",
    "Status",
  ];
  let body = [];
  !!productsListExportData && productsListExportData.map((data, index) => {
    const quantityAndPrice = !!data?.price ? Object.entries(data?.price)?.map(([key, value]) => `${key}:${value}`).join(',') + ',' : '';
    body.push({
      Sr: index + 1,
      Name: data.name,
      Reward: data.reward,
      RewardStatus: data.rewardStatus ? "Active" : "Inactive",
      Alias : data.alias,
      Slug: data.slug,
      Date: moment(data?.createdAt).format("DD/MM/YYYY"),
      Quantity: data?.quantity,
      Mrp: data?.mrp,
      QuantityAndPrice : quantityAndPrice,
      Weight: data?.weight,
      Orderby: data?.orderby,
      Minqty: data?.minqty,
      Maxqty: data?.maxqty,
      minStockQty: data?.minStockQty,
      ProductType: data?.productType,
      Stock: data?.instock ? "Active" : "Inactive",
      HotSelling: data?.hotSelling ? "Active" : "Inactive",
      Status: data?.status ? "Active" : "Inactive",
    });
  });
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
      <Row className="mb-2">
          <Col md={5}>
            <InputGroup>
              <InputGroup.Text id="inputGroup-sizing-default">
                <i className="bi bi-search"></i>
              </InputGroup.Text>
              <Form.Control
                aria-label="Default"
                placeholder="Search anything..."
                aria-describedby="inputGroup-sizing-default"
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>
          </Col>
          <Col md={5}></Col>
          <Col md={2} className="float-right">
            <Button className="float-right" variant="danger" onClick={() => window.location.reload()}>Clear</Button>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col md={3}>
            <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  if(status === ""){
                    dispatch(resetProductList());
                    dispatch(productsList({categories:e.target.value === "" ? null : [e.target.value]}))
                    setCategories([e.target.value])
                    dispatch(productsListExport({categories:e.target.value === "" ? null : [e.target.value]}))
                  }else{
                    dispatch(resetProductList());
                    dispatch(productsList({categories:e.target.value === "" ? null : [e.target.value], status}))
                    setCategories([e.target.value])
                    dispatch(productsListExport({categories:e.target.value === "" ? null : [e.target.value], status}))
                  }
                }}
              >
                <option value="">Select Category</option>
                {!!getCategoriesData?.list && getCategoriesData?.list?.map((data, index)=>{
                  return(
                    <option key={index} value={data?.id}>{data?.name}</option>
                  )
                })}
              </Form.Select>
          </Col>
          <Col md={2}>
            <Form.Select
                aria-label="Default select example"
                value={status}
                onChange={(e) => {
                  if(e.target.value === ""){
                    dispatch(resetProductList());
                    dispatch(productsList({categories}))
                    dispatch(productsListExport({categories}))
                    setStatus(e.target.value)
                  }else{
                    dispatch(resetProductList());
                    dispatch(productsList({status: (/true/).test(e.target.value), categories}))
                    dispatch(productsListExport({status: (/true/).test(e.target.value), categories}))
                    setStatus(e.target.value)
                  }
                }}
              >
                <option value="">Select Status</option>
                <option value={true}>Active</option>
                <option value={false}>InActive</option>
              </Form.Select>
          </Col>
          <Col md={4} className="d-flex">
          <Form.Control
              type="file"
              placeholder="Upload CSV"
              onChange={(e) => setProductsFile(e.target.files[0])}/><Button
              variant="success"
              className="float-right w-50 px-0"
              onClick={() => {dispatch(uploadProducts({
                "products" : productsFile
              }))
              setTimeout(() => {
                dispatch(resetProductList());
                dispatch(productsList());
              }, 800)}}
            >
              <i className="bi bi-cloud-arrow-up-fill"></i> CSV
            </Button>
          </Col>
          <Col md={2}>
            <ExportPdf/>
          </Col>
          <Col md={1}>
          <CSVLink data={body} headers={header} filename={'Pcdeals Hardware Products.csv'}>
            <Button>Export</Button>
          </CSVLink>
          </Col>
        </Row>
        <table className="table mt-4 table-scrolling ">
          <thead className="normalplace">
            <tr>
              <th className="over-col-size">Sr</th>
              <th className="over-col-size">Name</th>
              <th className="over-col-size">Reward</th>
              <th className="over-col-size">RewardStatus</th>
              <th className="over-col-size">Img</th>
              <th className="over-col-size">OrderBy</th>
              {/* <th className="over-col-size">Date</th> */}
              <th className="over-col-size">Qty</th>
              <th className="over-col-size">MinStockQty</th>
              <th className="over-col-size">MRP</th>
              <th className="over-col-size">ProductType</th>
              <th className="over-col-size">Stock</th>
              <th className="over-col-size">HotSelling</th>
              <th className="over-col-size">Status</th>
              <th className="over-col-size">Action</th>
            </tr>
          </thead>
          <tbody>
            {!!productsListData &&
              productsListData.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>
                      <Link to={`/product/${data?.slug}`}>{data?.name}</Link>
                      {
                        data?.alias && <>
                        <br/><b>({data?.alias})</b>
                        </>
                      }
                    </td>
                    <td>{data?.reward}</td>
                    <td><b>{data?.rewardStatus ? <span className="text-success">Active</span> : <span className="text-danger">InActive</span>}</b></td>
                    <td>
                      <ProductImageUpdate
                        images={data?.images}
                        productId={data?.id}
                        dimg={data?.defaultImage}
                      />
                    </td>
                    <td>
                      <b>{data?.orderby}</b>
                    </td>
                    {/* <td>{moment(data?.createdAt).format("DD/MM/YYYY")}</td> */}
                    <td>{data?.quantity}</td>
                    <td
                      className={
                        data?.minStockQty > data?.quantity ? "text-danger blink_img" : "text-success"
                      }
                    >
                      <b>{data?.minStockQty}</b>
                    </td>
                    <td>{data?.mrp}</td>
                    <td>{data?.productType}</td>
                    <td>
                      <b>
                        {data?.instock === true ? (
                          <span className="text-success">InStock</span>
                        ) : (
                          <span className="text-danger">OutOfStock</span>
                        )}
                      </b>
                    </td>
                    <td>
                      <b>
                        {data?.hotSelling === true ? (
                          <span className="text-success">Active</span>
                        ) : (
                          <span className="text-danger">InActive</span>
                        )}
                      </b>
                    </td>
                    <td>
                      <b>
                        {data?.status === true ? (
                          <span className="text-success">Active</span>
                        ) : (
                          <span className="text-danger">InActive</span>
                        )}
                      </b>
                    </td>
                    <td>
                      <UpdateProduct
                        pinstock={data?.instock}
                        pstatus={data?.status}
                        pname={data?.name}
                        pslug={data?.slug}
                        psku={data?.sku}
                        pweight={data?.weight}
                        pdescription={data?.description}
                        pmrp={data?.mrp}
                        psale={data?.sale}
                        pminqty={data?.minqty}
                        pmaxqty={data?.maxqty}
                        pquantity={data?.quantity}
                        pprice={data?.price}
                        pcategories={data?.categories}
                        psubCategories={data?.subcategories}
                        pbrand={data?.brand}
                        ptax_class={data?.tax_class}
                        pattributes={data?.attributes}
                        pmetaTitle={data?.metaTitle}
                        pmetaTags={data?.metaTags}
                        pmetaSchema={data?.metaSchema}
                        pmetaDescription={data?.metaDescription}
                        pbackordering={data?.backordering}
                        pproductType={data?.productType}
                        pproductOptions={data?.productOptions}
                        warrantyProcedure={data?.warranty_procedure}
                        pwarranty={data?.warranty}
                        id={data?.id}
                        photSelling={data?.hotSelling}
                        porderby={data?.orderby}
                        categoriesSearchList={categories}
                        minStockQty={data?.minStockQty}
                        alias={data?.alias}
                        reward={data?.reward}
                        rewardStatus={data?.rewardStatus}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {!!productsBlank && productsBlank.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  productsList({
                    search: search,
                    page: page + 1,
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default ProductsList;
