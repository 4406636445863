import React, { useEffect, useState } from "react";
import Footer from "../Sub/Footer";
import { connection, home, shop } from "../../const";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, InputGroup, Form, Table } from "react-bootstrap";
import sumBy from "lodash/sumBy";
import { Link, useNavigate } from "react-router-dom";
import {
  getCheckoutDeatils,
  getOffers,
  getOrderRewardSlab,
  getSetting,
} from "../../reducers/commonReducer";
import Header from "../Sub/Header";
import PaymentTypes from "../Sub/PaymentTypes";
import { orderCreate, shippingCost } from "../../reducers/orderDetailsReducer";
import ItemTable from "./ItemTable";
import { CODConfirmation } from "../Sub/CODConfirmation";
import codblue from "../../images/codblue.png";
import specialoffer from "../../images/specialoffer.png";
import OfferPopUp from "./OfferPopUp";

const Checkout = () => {
  const dispatch = useDispatch();
  let width = window.innerWidth;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [pickup, setPickup] = useState(false);
  const [cod, setCod] = useState(false);
  const [payLater, setPayLater] = useState(false);
  const { getCartlistData, orderRewardSlab, getOffersData, applyCouponData, getSettingData } = useSelector(
    (state) => state.commonReducer
  );
  const { getUserProfileList } = useSelector(
    (state) => state.orderDetailReducer
  );
  let totalAmount;
  const handleShow = () => setShow(true);
  useEffect(() => {
    dispatch(getCheckoutDeatils());
    dispatch(shippingCost());
    dispatch(getSetting());
    dispatch(getOffers())
  }, []);
  useEffect(() => {
    !!getCartlistData?.list &&
      getCartlistData?.list.length <= 0 &&
      navigate(home);
  }, [getCartlistData]);

  const { shippingCostData } = useSelector((state) => state.orderDetailReducer);

  let cartDetails = sumBy(
    !!getCartlistData?.list && getCartlistData?.list,
    function (o) {
      return o.quantity;
    }
  );
  totalAmount = sumBy(
    !!getCartlistData?.list && getCartlistData?.list,
    function (o) {
      if (o.customize.length) {
        let customizePrice = o?.customize.reduce(
          (total, item) => total + item.price,
          0
        );
        return (
          ((o.proudct?.sale ? o.proudct?.sale : o.proudct?.mrp) +
            customizePrice) *
          o?.quantity
        );
      } else {
        let discountPercent = 0;
        // Find the discount price based on the quantity
        for (const q in o?.proudct?.price) {
          if (o?.quantity >= parseInt(q)) {
            discountPercent = o?.proudct?.price[q];
          }
        }
        return (
          (discountPercent
            ? discountPercent
            : o.proudct?.sale
              ? o.proudct?.sale
              : o.proudct?.mrp) * o?.quantity
        );
      }
    }
  );

  const shippingCostCalculate =
    !!shippingCostData && shippingCostData?.cost > 0
      ? shippingCostData?.cost
      : 0;

  const totalPayAmount =
    (applyCouponData?.coupon?.amount || localStorage.getItem("couponAmount")
      ? totalAmount -
      (applyCouponData?.coupon?.amount ||
        localStorage.getItem("couponAmount"))
      : parseFloat(totalAmount)) +
    (!pickup && parseFloat(shippingCostCalculate)) +
    (payLater &&
      ((parseFloat(totalAmount) +
        (!pickup && parseFloat(shippingCostCalculate))) *
        parseFloat(getSettingData?.setting?.spacialuser)) /
      100);
  //open pcdeals site
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const apiResp = async (values) => {
    const apiResponse = await dispatch(
      orderCreate({
        spacialuser: payLater,
        pickup: false,
        cod: false,
        payby: false,
      })
    );
    if (apiResponse?.payload?.status) {
      setTimeout(() => {
        navigate(`/pay-now-buy-later-resp/${apiResponse?.payload?.status}`)
      }, 1000)
    }
  };
  useEffect(() => {
    dispatch(getOrderRewardSlab({
      amount: totalAmount
    }))
  }, [totalAmount]);
  return (
    <>
      <Header />
      <div className="container pb-5">
        <main>
          <div className="py-3 text-center"></div>
          <div className="row g-5">
            <div className="col-md-12 col-lg-12 order-md-last">
              <h4 className="d-flex justify-content-between align-items-center mb-3">
                <b>
                  {" "}
                  <span className="your-cart">Your cart</span>{" "}
                </b>

                <span className="badge your-cart card-bg border rounded-pill">
                  {cartDetails}
                </span>
              </h4>
              <ItemTable cartDetails={cartDetails} totalAmount={totalAmount} />
              <img className="w-auto blink_img mt-2" src={specialoffer} />
              {/* {getSettingData?.setting?.pcdeals && (
                <Row className="mt-2">
                  <p className="fw-bold discount-color f-18">
                    {getUserProfileList?.pcdealUserId?.length > 0 ? <>
                      <img className="w-auto blink_img" src={specialoffer}/><br/> 👉 If your cart value is more than {getSettingData?.setting?.cashbackcardvalue}/-, You will get a cashback of {getSettingData?.setting?.cashback}% in your <span className="text-success"><b>PC Deals India</b></span> cashback wallet.
                    </> : (
                      <>
                        <img className="w-auto blink_img" src={specialoffer}/><br/> 👉 If your Cart Value is more than {getSettingData?.setting?.cashbackcardvalue}/-, You will get a cashback of {getSettingData?.setting?.cashback}% in your <span className="text-success"><b>PC Deals India</b></span> cashback wallet. If you have an account on <span className="text-success"><b>PC Deals India</b></span>, then click{" "}
                        <Link to={connection}>
                          <Button className="theme-main-btn">Connect</Button>
                        </Link>{" "}
                        If you don't have an account on <span className="text-success"><b>PC Deals India</b></span>, then{" "}
                        <Button
                          className="theme-main-btn"
                          onClick={() =>
                            openInNewTab("https://www.pcdealsindia.com/")
                          }
                        >
                          Click Here
                        </Button>
                      </>
                    )}
                  </p>
                </Row> 
              )} */}
              {
                getOffersData?.offers?.length > 0 && getOffersData?.offers?.map((data, index) => <>
                  {data?.status && <p style={{ color: data?.color }} key={index} className="f-18"><b>👉 {data?.value}</b></p>}
                </>)
              }
              <Row className="mt-2">

                <Col md={4} sm={12} xs={12}>
                  <Link to={shop}>
                    <Button className="theme-main-btn w-100 m-1">
                      Buy More Items <i className="bi bi-arrow-right"></i>
                    </Button>
                  </Link>
                </Col>
              </Row>
              <Row className="p-2">
                <ul className="list-group p-0 all-calculated-amount-tbl">
                  {((!!applyCouponData && applyCouponData?.coupon) ||
                    localStorage.getItem("couponAmount")) && (
                      <>
                        <li className="list-group-item d-flex justify-content-between">
                          <strong className="col-md-10">
                            Congratulations Your Coupon Amount🎉🎉
                          </strong>
                          <strong className="col-md-2">
                            Rs.{" "}
                            {applyCouponData?.coupon?.amount
                              ? applyCouponData.coupon.amount
                              : localStorage.getItem("couponAmount")}
                          </strong>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                          <strong className="col-md-10">
                            After Apply Coupon Amount
                          </strong>
                          <strong className="col-md-2">
                            {totalAmount -
                              (applyCouponData?.coupon?.amount
                                ? applyCouponData?.coupon?.amount
                                : localStorage.getItem("couponAmount"))} /-
                          </strong>
                        </li>
                      </>
                    )}
                  {
                    <>
                      <div className="mt-4">
                        {shippingCostData?.cost > 0 && (
                          <li className="list-group-item d-flex f-16 justify-content-between">
                            <b className="col-md-10">
                              Total Amount(Inclusive of GST)
                            </b>
                            <b className="col-md-2">
                              {totalAmount} /-
                            </b>
                          </li>
                        )}
                        {shippingCostData?.cost > 0 && (
                          <li className="list-group-item d-flex f-16 justify-content-between">
                            <b className="col-md-10">
                              Shipping Charges 🚚🚚🚚
                            </b>
                            <b className="col-md-2">
                              {+shippingCostData?.cost} /-{" "}
                              {shippingCostData?.weight ? `( ${(+(shippingCostData?.weight) / 1000).toFixed(1)}Kg )` : null}
                            </b>
                          </li>
                        )}
                        {payLater && (
                          <li className="list-group-item d-flex f-16 justify-content-between">
                            <b className="col-md-10">
                              Buy Now Pay Later Charges
                            </b>
                            <b className="col-md-2">
                              {`${totalPayAmount?.toFixed(0) - totalAmount?.toFixed(0)}/- (${parseFloat(getSettingData?.setting?.spacialuser)}%)`}
                            </b>
                          </li>
                        )}
                        <li className="list-group-item d-flex f-16 justify-content-between">
                          <b className="col-md-10">Total Amount Payable</b>
                          <b className="col-md-2">
                            {totalPayAmount.toFixed(0)} /-
                          </b>
                        </li>
                        {
                          (getCartlistData?.rewardTotal > 0 || shippingCostData?.cost > 0 || orderRewardSlab?.slab > 0) &&
                          <>
                            <h6 class="mt-1 mb-2 reward-offer-single mb-0 m-f-13 w-100"><b><span> <i class="bi bi-gift-fill"></i> Your total Reward Points in this order <i class="bi bi-gift-fill"></i> </span></b></h6>
                            <Table striped bordered>
                              <tbody>
                                {getCartlistData?.rewardTotal > 0 && <tr>
                                  <td className="font-weight-bold m-f-12 col-md-10 m-w-100">👉 Reward Points for product</td>
                                  <td className="font-weight-bold m-f-12 col-md-4">{getCartlistData?.rewardTotal?.toFixed(0)}</td>
                                </tr>}
                                {orderRewardSlab?.slab > 0 && <tr>
                                  <td className="font-weight-bold m-f-12 col-md-10 m-w-100">👉 Reward Points for Cart Value({totalAmount.toFixed(0)} * {orderRewardSlab?.rate}%)</td>
                                  <td className="font-weight-bold m-f-12 col-md-4">{orderRewardSlab?.slab?.toFixed(0)}</td>
                                </tr>}
                                {shippingCostData?.cost > 0 && <tr>
                                  <td className="font-weight-bold m-f-12 col-md-10 m-w-100">👉 Reward Points for Shipping Charges</td>
                                  <td className="font-weight-bold m-f-12 col-md-4">{(+shippingCostData?.cost)?.toFixed(0)}</td>
                                </tr>}
                              </tbody>
                            </Table>
                            <li class="bg-success text-white list-group-item d-flex f-16 justify-content-between">
                              <b class="col-md-10 m-w-100">Total Reward Points for this order</b>
                              <b class="col-md-2">{(getCartlistData?.rewardTotal ? +getCartlistData?.rewardTotal : 0) + (shippingCostData?.cost ? +shippingCostData?.cost : 0) + (orderRewardSlab?.slab ? +orderRewardSlab?.slab : 0)}</b></li>
                            {/* <tr className="bg-success mt-2 w-100">
                                <td className="font-weight-bold m-f-12 col-md-10 m-w-100 text-white">Total Reward Points for this order</td>
                                <td className="font-weight-bold m-f-12 col-md-4 text-white"><b>{(getCartlistData?.rewardTotal ? getCartlistData?.rewardTotal : 0) + (shippingCostData?.cost ? shippingCostData?.cost : 0) + (orderRewardSlab?.slab ? orderRewardSlab?.slab : 0)}</b></td>
                              </tr> */}
                          </>
                        }
                        <Row className="mt-2">
                          <Col></Col>
                          {
                            getOffersData?.description?.length > 0 && <Col md={4} sm={12} xs={12}>
                              <OfferPopUp description={getOffersData?.description} />
                            </Col>
                          }
                        </Row>
                        {/* <li className="list-group-item d-flex justify-content-between">
                          <strong className="col-md-10">Total Amount Payable</strong>
                          <strong className="col-md-2">
                            {totalPayAmount.toFixed(0)} /-
                          </strong>
                        </li>
                        {getCartlistData?.rewardTotal > 0 && <li className="list-group-item d-flex justify-content-between">
                          <strong className="col-md-10 text-info">After your order is complete, you will receive a product-wise reward of <span className="text-danger">{getCartlistData?.rewardTotal?.toFixed(0)}/-</span> in <Link className="text-success"><b>Pcdeals Reward</b></Link>.</strong>
                        </li>
                        }
                        {orderRewardSlab?.slab?.length > 0 && <li className="list-group-item d-flex justify-content-between">
                          <strong className="col-md-10 text-warning">After your order is complete, you will receive a cart value reward of <span className="text-danger">{orderRewardSlab?.slab?.toFixed(0)}/-</span> in <Link className="text-success"><b>Pcdeals Reward</b></Link>.</strong>
                        </li>
                        } */}
                      </div>
                    </>
                  }
                </ul>
              </Row>
              {getUserProfileList?.spacialuser &&
                getUserProfileList?.wallet >=
                totalPayAmount +
                parseInt(getSettingData?.setting?.spacialuser) && (
                  <>
                    <Row className="px-2">
                      <InputGroup className="mb-3 p-0">
                        <InputGroup.Checkbox
                          aria-label="Checkbox for following text input"
                          onChange={(e) => setPayLater(!payLater)}
                        />
                        <Form.Control
                          aria-label="Text input with checkbox"
                          placeholder="Check for Buy Now Pay Later"
                          disabled
                        />
                      </InputGroup>
                    </Row>
                    {payLater && (
                      <Row className="px-2">
                        <Button
                          className="theme-main-btn w-100"
                          onClick={apiResp}>
                          Buy Now Pay Later <i className="bi bi-bag-check-fill"></i> ({totalPayAmount.toFixed(0)} /-)
                        </Button>
                      </Row>
                    )}
                  </>
                )}
              {!payLater && (

                <>
                <Row>
                   {/* {getUserProfileList?.codStatus && (
                    <Col md={4} sm={12} xs={12} className="mt-2 mb-2">
                      <label
                        className={`userAddress-lable ${cod && `selectedBox`} ${width > 449 && `w-100`}`}
                      >
                        <div className={`user-new-address ${width >= 468 ? 'pt-2 pb-2' : ''}`}>
                          <input
                            type="checkbox"
                            name="cod"
                            checked={cod}
                            onClick={!cod && handleShow}
                            onChange={(e) => cod && setCod(false)}
                            disabled={pickup ? true : false}
                            hidden
                          />
                          <div>
                          {getUserProfileList.codStatus && (
                           <img className="w-100" src={codblue} alt="Description" />
                            )}
                          </div>
                        </div>
                      </label>
                    </Col>
                     )} */}
                    <Col md={4} sm={12} xs={12} className="mt-2 mb-2">
                      <label className={`userAddress-lable ${cod && `selectedBox`} ${width > 449 && `w-100`}`}>
                        <PaymentTypes
                          cod={cod}
                          pickup={pickup}
                          couponCode={
                            !!applyCouponData?.coupon?.code &&
                            applyCouponData?.coupon?.code
                          }
                          totalAmount={totalPayAmount}
                        />
                      </label>
                    </Col>
                    <CODConfirmation
                      show={show}
                      activeShow={setShow}
                      cod={cod}
                      changeCod={setCod}
                      totalAmount={totalPayAmount}
                    />
                  </Row>
                </>

              )}
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Checkout;
