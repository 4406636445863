import React, { useState } from 'react';
import moment from "moment";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  pdf,
  Link,
  Image,
} from '@react-pdf/renderer';
import { saveAs } from 'file-saver'; // To download the generated PDF
import { Button, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import darklogo from "../../images/darklogo.png";

// Function to chunk the array into groups of 2
const chunkArray = (array, size) => {
  return Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
    array.slice(index * size, index * size + size)
  );
};

// Styles
const styles = StyleSheet.create({
  textMarginBottom: {
    marginBottom: "10px"
  },
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 20,
  },
  section: {
    marginBottom: 10,
    padding: 10,
    border: '1px solid #ccc',
  },
  header: {
    fontSize: 20,
    marginBottom: 10,
    textAlign: 'center',
  },
  productRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  columnLeft: {
    flex: 6, // 70% of the space
    marginRight: 10,
  },
  columnRight: {
    flex: 4, // 30% of the space
  },
  image: {
    width: '200px',
    height: '200px',
    objectFit: 'contain',
  },
  link: {
    marginTop: 10,
    fontSize: 20,
    fontWeight:'bold',
    color: 'blue',
    textDecoration: 'underline',
  },
  priceTable: {
    marginTop: 10,
    border: '1px solid #ccc',
    borderCollapse: 'collapse',
    width: '100%',
  },
  priceTableRow: {
    borderBottom: '1px solid #ccc',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  priceTableCell: {
    padding: 5,
    flex: 1,
    textAlign: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
  logoImg: {
    width: '30%',
  },
  logoDesign: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  termsContainer: {
    position: 'absolute',
    bottom: 20, // Ensures it stays at the bottom
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  textDanger:{
    color:"red"
  },
  textSuccess:{
    color:"green"
  },
  buyNowButton:{
    color:"#fff !important",
    backgroundColor: "#ff832b",
    // backgroundColor: "#0b2b42",
    border: "2px solid transparent",
    borderRadius: "30px",
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "18px",
    padding: "5px 0px 3px 0px",
    marginTop: "15px",
    marginBottom:"10px"
  },
  font12:{
    fontSize:"12px"
  },
  font16:{
    fontSize:"16px"
  }
});

// Document Component
const MyDocument = ({ data }) => {
  const productChunks = chunkArray(data, 2); // Split into pages with 2 products each

  return (
    <Document>
      {productChunks.map((chunk, pageIndex) => (
        <Page key={pageIndex} size="A4" style={styles.page}>
          {/* Header (Repeated on Every Page) */}
          <View style={styles.logoDesign}>
            <Image src={darklogo} style={styles.logoImg} />
          </View>
          <Text style={styles.header}>RATE LIST</Text>
          <Text style={styles.textCenter}>Date: {moment().format("DD/MM/YYYY")}</Text>

          {/* Product List (Max 2 per Page) */}
          {chunk.map((product, index) => (
            <View key={index} style={styles.section}>
              <View style={[styles.textCenter, styles.textMarginBottom]}>
                <Text style={styles.textSuccess}>{product.name}</Text>
                {/* {product.productType === "customize" && <Text>{`${product?.productOptions?.processer?.[0]?.name}, ${product?.productOptions?.ram?.[0]?.name}, ${product?.productOptions?.storage?.[0]?.name}`}</Text>} */}
              </View>
              <View style={styles.productRow}>
                <View style={styles.columnLeft}>
                  <View style={{ flexGrow: 1 }}>
                  {!(Object.keys(product?.price)?.length > 0) && <>
                    {product.productType === "customize" ? 
                  <Text>Starts From: {(product.sale ?? 0) +  
                    (product?.productOptions?.processer?.[0]?.price ?? 0) +  
                    (product?.productOptions?.ram?.[0]?.price ?? 0) +  
                    (product?.productOptions?.storage?.[0]?.price ?? 0)}/-(GST Inclusive)</Text> : <Text>Offer Price: {product.sale}/-(GST Inclusive)</Text>}
                  </>}
                 
                  {/* <Text>Min Order Qty: {product?.minqty}</Text> */}
                  {/* Conditional rendering for Prices */}
                  {product?.price && Object.keys(product.price).length > 0 && (
                    <View style={styles.priceTable}>
                      <View style={styles.priceTableRow}>
                        <Text style={styles.priceTableCell}>Qty</Text>
                        <Text style={styles.priceTableCell}>Sale Price {"\n"}<Text style={styles.font12}>(GST Inclusive)</Text></Text>
                      </View>
                      <View style={styles.priceTableRow}>
                          <Text style={styles.priceTableCell}>{product?.minqty}</Text>
                          <Text style={styles.priceTableCell}>{product.sale}/-</Text>
                      </View>
                      {Object.entries(product.price).map(([key, value], i) => (
                        <View key={i} style={styles.priceTableRow}>
                          <Text style={styles.priceTableCell}>{key}</Text>
                          <Text style={styles.priceTableCell}>{value}/-</Text>
                        </View>
                      ))}
                    </View>
                  )}
                   </View>
                   
                   {product.productType === "customize" && <View style={{ flexGrow: 1 }}><Text style={[styles.textDanger, styles.font16]}>This product is customizable, so the price may change based on the configuration.</Text> </View>}
                  
                  <Text
                    src={`https://pcdealshardware.com/product/${product?.slug}?productType=${product?.productType}`}
                    style={[styles.buyNowButton]}
                  >
                   {product.productType === "customize" ? "Select Configuration" : "Buy Now"}
                  </Text>
                  {!!product.reward && <Text>Earn Reward Points: <Link style={styles.link} src={`https://pcdealshardware.com/earn-reward-points`}>{product.reward} Per Unit</Link></Text>}
                </View>

                <View style={styles.columnRight}>
                  {product?.images?.[product?.defaultImage] && (
                    <Image src={`/${product?.images?.[product?.defaultImage]}`} style={styles.image} />
                  )}
                   <Text style={[(product.instock && product?.quantity > 0) ? styles.textSuccess : styles.textDanger, styles.textCenter]}>
                    {(product.instock && product?.quantity > 0) ? "In Stock" : "Out Of Stock"}
                  </Text>
                </View>
              </View>
            </View>
          ))}
            <View style={styles.termsContainer}>
                  <Link
                    src={`https://pcdealshardware.com/terms-and-conditions`}
                    style={styles.link}
                  >
                   Terms and Conditions
                  </Link>
            </View>
        </Page>
      ))}
    </Document>
  );
};

const ExportPdf = ({animatedBtn, recordList, catName}) => {
  const { productsListExportData } = useSelector((state) => state.commonReducer);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const generatePdfPreview = async () => {
    const blob = await pdf(<MyDocument data={recordList ? recordList : productsListExportData} />).toBlob();
    const url = URL.createObjectURL(blob);
    setPdfUrl(url);
  };
 
  const handleDownload = async () => {
    setIsLoading(true); // Show loader
    try {
      const blob = await pdf(<MyDocument data={recordList ? recordList : productsListExportData} />).toBlob();
      saveAs(blob, "Pcdeals_Hardware_Products.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsLoading(false); // Hide loader
    }
  }
  return (
    <div>
      {/* <Button variant="warning" onClick={generatePdfPreview}>
        Show PDF
      </Button>
      {pdfUrl && <iframe src={pdfUrl} width="100%" height="600px" title="PDF Preview"></iframe>} */}
      {animatedBtn ? <Button onClick={handleDownload} disabled={isLoading} className="w-full w-100 py-2 text-white font-bold text-lg rounded-lg animate-gradient">
                  Download Rate List ({catName})
                </Button> : <Button variant="warning" className="f-right" onClick={handleDownload} disabled={isLoading}>
        {isLoading ? <Spinner animation="border" size="sm" /> : "Export PDF"}
      </Button>}
    </div>
  );
};

export default ExportPdf;
