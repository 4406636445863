import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "../Components/SideMenu";
import { dashboardRecord, getDashBoard } from "../reducers/commonReducer";

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dashboardRecord());
  }, []);
  const { dashboardRecordData } = useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
        <Row className="mb-3">
          <b>
            <h1>Dashboard</h1>
          </b>
          <div className="dashboard-bottom"></div>
        </Row>

        <Row>
          <Col md={4}>
            <div className="card text-black shadow border  mb-3">
              <div className="card-body">
                <b>
                  <p className="card-title fs-5">Today Orders </p>
                </b>
                <p className=" fs-4 ">
                  {" "}
                  <h2>{dashboardRecordData?.countOrders?.today}</h2>
                </p>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className="card text-black shadow border  mb-3">
              <div className="card-body">
                <b>
                  <p className="card-title fs-5">This Week Orders</p>
                </b>
                <p className="card-text fs-3 ">
                  {" "}
                  <h2>{dashboardRecordData?.countOrders?.thisWeek}</h2>
                </p>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className="card text-black shadow border  mb-3">
              <div className="card-body">
                <b>
                  <p className="card-title fs-5">Total Orders</p>
                </b>
                <p className="card-text fs-3 ">
                  {" "}
                  <h2>{dashboardRecordData?.countOrders?.totals}</h2>
                </p>
              </div>
            </div>
          </Col>

          {/* <Col md={4}>
            <h2>{dashboardRecordData?.countOrders?.today}</h2>
            <h1>today orders</h1>
          </Col> */}
          {/* <Col md={4}>
            <h2>{dashboardRecordData?.countOrders?.thisWeek}</h2>
            <h1>this week orders</h1>
          </Col> */}

          {/* <Col md={4}>
            <h2>{dashboardRecordData?.countOrders?.totals}</h2>
            <h1>total orders</h1>
          </Col> */}
        </Row>
        <Row>
          <Col md={4}>
            <div className="card text-black shadow border  mb-3">
              <div className="card-body">
                <b>
                  <p className="card-title fs-5">Today Payment </p>
                </b>
                <p className="card-text fs-3 ">
                  {" "}
                  <h2>
                    {dashboardRecordData?.countpayments?.today
                      ? dashboardRecordData?.countpayments?.today
                      : 0}
                  </h2>
                </p>
              </div>
            </div>
          </Col>
          {/* <Col md={4}>
            <h2>
              {dashboardRecordData?.countpayments?.today
                ? dashboardRecordData?.countpayments?.today
                : 0}
            </h2>
            <h1>today Payment</h1>
          </Col> */}
          <Col md={4}>
            <div className="card text-black shadow border  mb-3">
              <div className="card-body">
                <b>
                  <p className="card-title fs-5">This Week Payment </p>
                </b>
                <p className="card-text fs-3 ">
                  {" "}
                  <h2>
                    {" "}
                    {dashboardRecordData?.countpayments?.thisWeek
                      ? dashboardRecordData?.countpayments?.thisWeek
                      : 0}{" "}
                  </h2>
                </p>
              </div>
            </div>
          </Col>

          {/* <Col md={4}>
            <h2>
              {dashboardRecordData?.countpayments?.thisWeek
                ? dashboardRecordData?.countpayments?.thisWeek
                : 0}
            </h2>
            <h1>this week Payment</h1>
          </Col> */}

          <Col md={4}>
            <div className="card text-black shadow border  mb-3">
              <div className="card-body">
                <b>
                  <p className="card-title fs-5">Total Payment </p>
                </b>
                <p className="card-text fs-3 ">
                  {" "}
                  <h2>
                    {" "}
                    {dashboardRecordData?.countpayments?.totals
                      ? dashboardRecordData?.countpayments?.totals
                      : 0}{" "}
                  </h2>
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <div className="card text-black shadow border  mb-3">
              <div className="card-body">
                <b>
                  <p className="card-title fs-5">Today Customer </p>
                </b>
                <p className="card-text fs-3 ">
                  {" "}
                  <h2> {dashboardRecordData?.countCustomers?.today} </h2>
                </p>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className="card text-black shadow border  mb-3">
              <div className="card-body">
                <b>
                  <p className="card-title fs-5">This Week Customer</p>
                </b>
                <p className="card-text fs-3 ">
                  {" "}
                  <h2> {dashboardRecordData?.countCustomers?.thisWeek} </h2>
                </p>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className="card text-black shadow border  mb-3">
              <div className="card-body">
                <b>
                  <p className="card-title fs-5">Total Customer</p>
                </b>
                <p className="card-text fs-3">
                  {" "}
                  <h2> {dashboardRecordData?.countCustomers?.totals} </h2>
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div className="card text-black shadow border  mb-3">
              <div className="card-body">
                <b>
                  <p className="card-title fs-5">Today Completed Order Amount </p>
                </b>
                <p className="card-text fs-3 ">
                  {" "}
                  <h2> {(dashboardRecordData?.completedOrder?.today)?.toFixed(2)} </h2>
                </p>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className="card text-black shadow border  mb-3">
              <div className="card-body">
                <b>
                  <p className="card-title fs-5">This Week Completed Order Amount</p>
                </b>
                <p className="card-text fs-3 ">
                  {" "}
                  <h2> {(dashboardRecordData?.completedOrder?.thisWeek)?.toFixed(2)} </h2>
                </p>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className="card text-black shadow border  mb-3">
              <div className="card-body">
                <b>
                  <p className="card-title fs-5">Total Completed Order Amount</p>
                </b>
                <p className="card-text fs-3">
                  {" "}
                  <h2> {(dashboardRecordData?.completedOrder?.totals)?.toFixed(2)} </h2>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
